import React, { useState } from "react";

export default function Form() {
    const [isOn, setIsOn] = useState(false)

    return (
        <div className="flex flex-col space-y-6 bg-primary-alt py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
            <div className="mx-auto text-center">
                <p className="text-secondary uppercase font-body tracking-widest mb-4">Ready to get vending?</p>
                <h1 className="text-5xl tracking-tight leading-10 font-extrabold text-white font-display sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                    Let's talk
                </h1>
                <p className="text-secondary font-body mt-4">
                    Fill out the form below and we will be in contact with you shortly.
                </p>
            </div>

            <div className="relative max-w-xl mx-auto font-body bg-primary p-12 rounded-lg">
                <div className="">
                    <form name="contact" action="/success" data-netlify="true" method="POST" data-netlify-honeypot="bot-field" className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">
                        <div>
                            <input type="hidden" name="form-name" value="contact" />
                            <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-primary bg">First name</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input name="first_name" id="first_name" required className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary" />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-primary">Last name</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input name="last_name" id="last_name" required className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary" />
                            </div>
                        </div>
                        <p className="hidden">
                            <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <div className="sm:col-span-2">
                            <label htmlFor="company" className="block text-sm font-medium leading-5 text-primary">Company</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input name="company" id="company" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-5 text-primary">Email</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input type="email" name="email" id="email" required className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone_number" className="block text-sm font-medium leading-5 text-primary">Phone Number</label><p className="text-sm text-gray-400">Format: 5554443333</p>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" name="phone_number" id="phone_number" required className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary placeholder-gray-600" />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-medium leading-5 text-primary">Message</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <textarea name="message" id="message" required rows="4" className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150 bg-secondary border-0 text-secondary"></textarea>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    {/* <!--
                Simple toggle
                On: "bg-indigo-600", Off: "bg-gray-200"
              --> */}
                                    <span tabIndex="0" role="checkbox" onClick={() => setIsOn(!isOn)} onKeyDown={() => setIsOn(!isOn)} aria-checked={isOn} className={`${isOn ? 'bg-yellow-300' : 'bg-secondary'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}>
                                        {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
                                        <input type="checkbox" name="privacy" aria-label="Privacy Policy Notice Checkbox" required className={`${isOn ? 'translate-x-3/5' : 'translate-x-0'} form-input inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`} />
                                    </span>
                                </div>
                                <div className="ml-3">
                                    <p className="text-base leading-6 text-secondary font-body">
                                        By selecting this, you agree to the <a href="/privacy" rel="noreferrer" target="_blank" className="font-medium text-secondary underline"> Privacy Policy</a>.
              </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <span className="w-full inline-flex rounded-md shadow-sm">
                                <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-black bg-yellow-300">
                                    Submit
            </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}