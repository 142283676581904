import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import NavBar from "../components/NavBar"
import Form from "../components/Form"

export default function Contact(props) {
    return (
        <Layout>
            <SEO
                title="Contact"
            />
            <NavBar />
            <Form />
            <Footer />

        </Layout>
    )
}
